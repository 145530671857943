.multimedia-swiper {
    position: relative;
    overflow: hidden;
    width: 100%;
    @media (min-width: 1400px) {
        max-width: 1200px;
    }
    margin: auto;
    background-color: $dark;
}
.multimedia-swiper-scrollpoint {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 50px;
    left: 50%;
    transform: translate(-50%);
    pointer-events: none;
    // background-color: red;
    z-index: 5;
}
.multimedia-swiper .swiper {
    height: 100%;
    width: 100%;
}
.multimedia-swiper .swiper-slide {
    height: 800px;
    width: 100%;
    overflow: hidden;
    // @media (min-width: 1400px) {
    //     height: 100%;
    //     width: 100%;
    // }
    @media (max-width: 900px) {
        height: 100vh;
    }
}

.multimedia-swiper__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(15px) brightness(50%);
    transform: translate3d(0, 0, 0);
}
.multimedia-swiper__featured-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 900px) {
        width: 100%;
        height: 50%;
    }
}
.multimedia-swiper .swiper-pagination {
    bottom: 1rem !important;
    padding: 0 !important;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
    width: max-content !important;
    margin: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
}
.multimedia-swiper .swiper-pagination-bullet {
    opacity: 1;
    width: 25px;
    height: 25px;
    margin: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: scale(0.9) translate(-50%, -50%);
        background-color: white;
        opacity: 0.6;
    }
    &:hover:before {
        opacity: 1;
        background-color: $blue;
    }
    &.swiper-pagination-bullet-active {
        background-color: transparent;
        &:before {
            opacity: 1;
            background-color: $yellow;
        }
    }
}
.multimedia-swiper__caption-wrapper {
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    @media (max-width: 900px) {
        width: 90%;
        top: 48%;
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        height: 300px;
        display: grid;
    }
}
.multimedia-swiper__edge-decor {
    height: 22%;
    aspect-ratio: 1 / 1;
    position: absolute;
    top: 0;
    right: 0;
    --edge-width: 15px;
    border-top: var(--edge-width) solid $yellow;
    border-right: var(--edge-width) solid $yellow;
    transform: translate(calc(var(--edge-width) - 1px), calc(var(--edge-width) * -1 + 1px));
    z-index: 2;
}
.multimedia-swiper__caption-container {
    position: relative;
    width: 100%;
    height: 100%;
    & * {
        font-size: 17px;
    }
}
.multimedia-swiper__caption {
    background: white;
    color: $dark;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    overflow: auto;
    display: block;
    max-height: 600px;
    @media (max-width: 900px) {
        max-height: 300px;
    }

    scrollbar-color: $dark transparent;

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $dark;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.multimedia-swiper__caption-wrapper--end-screen {
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 370px;
    overflow: visible;
    height: max-content;
    max-height: max-content;
    & .multimedia-swiper__caption {
        padding: 3rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        text-align: center;
        overflow: visible;
        height: max-content;
        max-height: max-content;
        border-radius: 1rem;
    }
}
.multimedia-swiper__app-icon {
    width: 50%;
    aspect-ratio: 1 / 1;
    display: block;
    border-radius: 2rem;
    object-fit: cover;
    margin: auto;
}
