.video-container {
    margin-bottom: 3rem;
    margin-top: 2rem;
    & iframe {
        background: black;
    }
}
.video-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    gap: 2rem;
    @media screen and (max-width: 1000px) {
        margin-top: 0.5rem;
        gap: 0.65rem;
    }
    // &_title {
    //     &:before,
    //     &:after {
    //         content: '"';
    //     }
    // }
}

.react-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    @media screen and (max-width: 1000px) {
        padding-top: 130%;
    }
    // @media screen and (max-width: 600px) {
    //     padding-top: 70%;
    // }
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.vimeo-container {
    position: relative;
}
.player__icon {
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: center;
    align-self: center;
    z-index: 2;
    top: 0;
    position: absolute;
    bottom: 0;

    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    // margin-top: -56.26%;
    // @media screen and (max-width: 1000px) {
    //     margin-top: -130%;
    // }
    // @media screen and (max-width: 600px) {
    //     margin-top: -70%;
    // }
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

.video-scrollytelling-wrapper {
    width: 100%;
    margin: 5rem 0 6rem 0;
    padding: 20vh 2rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1000px) {
        min-height: 100vh;
    }
}
.video-scrollytelling-bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    overflow: hidden;
    height: 100%;
    & > div {
        // background: rgba(0, 0, 0, 0.9);
        background: white;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_video {
        filter: brightness(40%);
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
    }
}
.video-scrollytelling-content {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    max-width: 1000px;
    margin: auto;
    // min-height: 300px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    & * {
        font-size: 1.5rem;
        @media screen and (max-width: 800px) {
            font-size: 1.35rem;
        }
        margin-bottom: 0;
        & + * {
            margin-bottom: 1rem;
        }
    }
}
