.section-swiper {
    position: relative;
}
.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical > .swiper-pagination-bullets {
    @media (min-width: 1400px) {
        right: 1.5rem !important;
    }
    @media (max-width: 992px) {
        right: 1.5rem !important;
        top: unset !important;
        bottom: 2rem;
        position: fixed;
        transform: scale(0.5) !important;
        transform-origin: center bottom;
    }
}
:root {
    --section-text-color: black;
}
.section-swiper {
    .swiper-slide {
        visibility: hidden;
    }

    .swiper-slide.swiper-slide-active {
        visibility: visible;
    }
    width: 100%;
    height: 100%;
    & .swiper-pagination-bullet {
        height: 20px;
        width: 20px;
        transition: $scrollChangeTransition;
        background-color: var(--section-text-color);
    }
    & .swiper-pagination-bullet-active {
        background-color: var(--section-text-color);
    }
}
