.illustration-wrapper {
    @media screen and (max-width: 992px) {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        min-height: 50vh;
        margin-top: 5rem;
        margin-bottom: 3rem;
    }
    @media screen and (max-width: 800px) {
        margin-top: 0;
    }
}
.illustration {
    position: relative;
    overflow: hidden;
    height: auto;
    margin: 0 auto;
    @media screen and (max-width: 1000px) and (orientation: landscape) {
        margin: 0;
        margin-right: auto;
        margin-top: -6vh;
    }
    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & svg {
        // padding: 1.5rem;
        width: auto !important;
        max-width: 600px !important;
        @media screen and (max-width: 800px) {
            max-width: 400px !important;
            max-height: 350px;
        }
    }
    // @media screen and (max-width: 800px) {
    //     transform: scale(0.8);
    // }
    &-scroller {
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: 60%;
        // background: rgba(red, 0.5);
        // opacity: 1 !important;
        @media screen and (max-width: 800px) {
            width: 100%;
            z-index: 1;
            top: 50%;
            bottom: 20%;
        }
    }
}
.illustration--fullwidth {
    @media screen and (max-width: 800px) {
        margin-top: 1rem;
        margin-left: -10vw;
        margin-right: -10vw;
    }
}
