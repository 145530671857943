.header {
    & h1 {
        font-size: clamp(3.4375rem, 2.7885rem + 2.8846vw, 6.25rem);
        line-height: 1;
    }
    & .split_grid {
        grid-template-columns: auto auto auto;
        @media screen and (max-width: 1000px) and (orientation: portrait) {
            display: flex;
            flex-direction: column;
        }
    }
}
