.embedsocial-hashtag {
    overflow-y: hidden;
    & iframe {
        transform: translateY(-80px);
    }
}
.social-feed {
    &_post {
        width: 50%;
        padding: 2rem;
        @media screen and (max-width: 1000px) {
            width: 100%;
            padding: 0;
            margin-bottom: 5rem;
        }
        &_image {
            object-fit: cover;
            width: 100%;
            border-radius: 1rem;
        }
        &_video {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            & video {
                object-fit: cover;
                position: relative;
                z-index: 1;
                width: 100%;
                border-radius: 1rem;
            }
            &_player-icon {
                position: absolute;
                z-index: 2;
                cursor: pointer;
                transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }
        }
        &_content {
            padding-top: 2rem;
        }
    }
}
