.accordion {
    &__icon {
        margin-right: 1rem;
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        &--expanded {
            transform: rotate(90deg);
        }
    }
    &__button {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: bold;
        font-size: 1.1rem;
        outline: none;
        user-select: none;
        // &[aria-expanded="true"] {
        // }
    }
    &__panel {
        padding-left: 2rem;
    }
}
