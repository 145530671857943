.article {
    padding-bottom: 150px;
    min-height: 100vh;
    padding-top: 50px;
    @media screen and (min-width: 1000px) {
        padding-top: 150px;
    }
    & .illustration {
        transform: none;
        & svg {
            width: auto !important;
            max-width: 100% !important;
            @media screen and (max-width: 800px) {
                max-width: 100% !important;
                max-height: 350px;
            }
        }
    }
}

.article-title {
    margin-top: 1rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 1000px) {
        margin-top: 5rem;
        font-size: 3rem;
        line-height: 3.5rem;
    }
}
.article--has-arrows {
    padding-top: 250px;
    @media screen and (max-width: 1000px) {
        padding-top: 70px;
    }
}
.article_btn-left,
.article_btn-right {
    top: 5rem;
    position: absolute;
    @media screen and (max-width: 1000px) {
        top: 2.5rem;
    }
}
.article_btn-left {
    left: 1.5rem;
    @media screen and (max-width: 1000px) {
        left: 0;
    }
}
.article_btn-right {
    right: 2.5rem;
    @media screen and (max-width: 1000px) {
        right: 0;
    }
}
.article-container {
    padding: 1rem 10vw;
    @media screen and (max-width: 1000px) {
        padding: 1rem 1.5rem;
    }

    &--fullwidth-on-sm {
        @media screen and (max-width: 1000px) {
            padding: 1rem 0;
        }
    }
}
.article-image {
    margin: 3rem 0;
    @media screen and (max-width: 1000px) {
        margin: 0;
    }
    & .image {
        max-height: 300px;
        margin: auto;
    }
}
.article > *:last-child {
    margin-bottom: 0 !important;
}
.article--no-margin {
    padding-bottom: 0 !important;
}
.article .video-scrollytelling-wrapper:last-child {
    margin-bottom: 0 !important;
}
