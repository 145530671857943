.footer {
    display: grid;
    height: 100vh;
    grid-template-rows: auto auto;
    @media screen and (max-width: 1000px) and (orientation: portrait) {
        grid-template-rows: auto auto;
    }
    width: 100%;
    & .title {
        font-size: clamp(2.1875rem, 2.1rem + 1.5vw, 4rem);
    }
    & .split {
        min-height: unset;
        height: 100%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1000px) {
            padding: 1.5rem;
        }
    }
    & .container {
        @media screen and (max-width: 1000px) and (orientation: portrait) {
            margin-bottom: auto;
        }
    }
    & .split_grid {
        @media screen and (max-width: 1400px) {
            padding: 1.5rem;
            max-width: 1000px;
            grid-template-columns: 1fr auto 1fr;
        }
        @media screen and (max-width: 1000px) and (orientation: portrait) {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0;
        }
    }
}
.footer-text {
    width: 100%;
    font-size: 16px;
}
.footer-links {
    background-color: $dark;
    padding: 1rem;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    color: white;
    margin-top: 2rem;
    padding-bottom: 50px;
    justify-content: center;
    @media screen and (max-width: 1000px) and (orientation: portrait) {
        justify-content: flex-start;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
    @media screen and (max-width: 400px) {
        padding: 0.2rem;
    }
    & .btn {
        font-size: 15px;
        color: $links;
        position: relative;
        display: flex;
        text-align: left;
        padding: 0.5rem;
        display: inline-block;
        margin-left: 0 !important;
        & .btn_icon-longarrow {
            margin-right: 10px;
        }
        @media screen and (max-width: 400px) {
            width: 100%;
            padding: 0;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            display: block;
            pointer-events: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        color: white;
        text-underline-offset: 3px;
        &:before {
            opacity: 1;
        }
        &:hover {
            text-decoration: underline;
            &:before {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}
