button {
    text-transform: inherit;
}
.btn {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    color: inherit;
    text-decoration: none;
    font-weight: 700;
    font-size: 0.9rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    & + .btn {
        margin-left: 1.5rem;
    }
    &--lg {
        padding: 2rem;
    }
    &--sm {
        font-size: 0.7rem;
    }
    &--next {
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            transform: translateX(10px);
        }
    }
    &--previous {
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            transform: translateX(-10px);
        }
    }
    &--rounded {
        border: 3px solid $dark;
        letter-spacing: 0;
        padding: 1rem 2rem;
        border-radius: 5rem;
        text-transform: none;
        font-weight: 400;
        text-transform: normal;
        @media screen and (max-width: 1000px) {
            font-size: 1.2rem;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        @media screen and (max-width: 400px) {
            padding: 1rem;
        }
    }
    &--link {
        color: $links;
    }
    &_icon-next {
        margin-left: 1rem;
        fill: currentColor;
        & * {
            fill: currentColor;
        }
    }
    &_icon-previous {
        margin-right: 1rem;
        fill: currentColor;
        & * {
            fill: currentColor;
        }
    }
    &_icon-link {
        margin-right: 1rem;
        fill: currentColor;
        & * {
            fill: currentColor;
        }
    }
    &_icon-longarrow {
        // @media screen and (max-width: 1000px) {
        //     display: none;
        // }
        margin-right: 1.5rem;
        & * {
            stroke: currentColor;
        }
    }
    &:hover {
        text-decoration: underline;
    }
}
