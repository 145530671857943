.split {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    padding: 0 10vw;
    width: 100vw;
    position: relative;
    overflow: hidden;
    transition: $scrollChangeTransition;
    &--fullheight {
        min-height: 100vh;
    }
    @media screen and (max-width: 1000px) {
        padding: 0 1.5rem;
        padding-top: 50px;
        min-height: 90vh;
        margin: auto;
        width: 100%;
        align-items: center;
    }
    @media screen and (max-width: 1000px) and (orientation: portrait) {
        align-items: flex-start;
        & .split_title {
            margin-top: 5rem;
            margin-bottom: 2rem;
            font-size: 3rem;
            line-height: 3.5rem;
        }
    }
    &_text {
        height: auto;
        max-width: 40ch;
        @media screen and (min-width: 1000px) {
            transform: translateY(-0.25rem);
        }

        @media screen and (max-width: 1000px) {
            max-width: 55ch;
        }
    }
    &_border {
        @media screen and (max-width: 1000px) {
            display: none;
        }
        @media screen and (min-width: 1000px) {
            background: $dark;
            width: 4px;
        }
    }
    &_grid {
        display: grid;
        width: 100%;
        grid-template-columns: auto auto auto;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 1000px) {
            gap: 5rem;
            margin: auto;
        }
        @media screen and (max-width: 1000px) and (orientation: portrait) {
            align-items: flex-start;
            grid-template-columns: 1fr;
        }
        @media screen and (max-width: 1000px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr !important;
        }
        &--single-column {
            display: block;
        }
    }
}
