.scrolldown {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.05rem;
    font-weight: bold;
    fill: $dark;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    // animation: fade 2s forwards alternate ease-in-out infinite;
    z-index: 1;
    @media (max-width: 1000px) {
        &--bottom-left {
            left: 2rem;
            transform: scale(0.8);
            transform-origin: bottom left;
        }
    }
    & svg {
        & * {
            fill: currentColor;
        }
        width: 1rem;
        animation: scrolldown 1s forwards alternate ease-in-out infinite;
    }
    // @media screen and (max-width: 1000px) {
    //     display: none;
    // }
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes scrolldown {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(10px);
    }
}
