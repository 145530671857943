#nav-wrapper:not(.nav-wrapper--open) {
    background-color: transparent !important;
}
.nav-wrapper {
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 99;
    color: $dark;
    transition: $scrollChangeTransition;
    &--open {
        background: white !important;
        color: $dark !important;
    }
    &--article {
        @media (max-width: 1000px) {
            background: white;
        }
    }
    &--hide {
        transform: translateY(-1.25rem);
        opacity: 0;
    }
    &--hide-mobile {
        @media (max-width: 900px) {
            transform: translateY(-1.25rem);
            opacity: 0;
        }
    }
}
.nav {
    background: transparent;
    position: relative;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    opacity: 1;
    color: inherit;
    @media (min-width: 1000px) {
        padding-bottom: 0;
    }
    & a {
        color: inherit;
    }
    @media (max-width: 1000px) {
        padding: 0;
        padding-top: 1rem;
        width: 100%;
        left: 50%;
        width: calc(100vw - 2.5rem);
        transform: translateX(-50%);
        border-bottom: 1px solid currentColor;
    }
    &_hamburger {
        padding-right: 1rem;
        transform: translateY(-0.2rem);
        @media screen and (max-width: 1000px) {
            padding-right: 0;
        }

        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        &:hover {
            transform: scale(1.1);
        }
    }
    &_logo {
        width: 10rem;
    }
}
.menu {
    background: white;
    position: fixed;
    height: 100vh;
    overflow-y: scroll;
    width: 100vw;
    z-index: 98;
    pointer-events: none;
    user-select: none;
    opacity: 0;

    transition: $scrollChangeTransition;
    padding: 10vw;
    visibility: hidden;
    @media screen and (min-width: 900px) {
        padding: 15vw;
    }
    @media screen and (min-width: 1400px) {
        padding: 10vw 25vw;
    }
    &--open {
        opacity: 1;
        pointer-events: all;
        user-select: auto;
        visibility: visible;
    }
    &_items {
        list-style: none;
        margin: 0;
        width: 100%;
        &_item {
            font-family: "Roboto Slab", serif;
            font-size: 2.5rem;
            line-height: 2.5rem;
            @media screen and (max-width: 1000px) {
                font-size: 2rem;
                line-height: 2rem;
            }
            color: #7f7f7f;
            width: 100%;
            padding: 0.5rem 0;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            & a {
                color: inherit;
                text-decoration: none;
                padding: 0;
                width: 100%;
                line-height: 2rem;
                &:hover {
                    text-decoration: underline;
                }
            }
            &--current {
                display: inline-block;
                color: $dark;
                & a {
                    border-left: 3px solid;
                    padding-left: 1rem;
                    margin-left: -1rem;
                }
            }
        }
    }
}
