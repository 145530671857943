// Roboto Slab
// font-family: 'Roboto Slab', serif;
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap");

// Roboto Light, bold and black + all italic
// font-family: 'Roboto', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,300;1,700;1,900&display=swap");
html {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: $dark;
    @media screen and (max-width: 900px) {
        font-size: 16px;
    }
}
h1 {
    font-family: "Roboto Slab", serif;
}
p {
    font-size: 1rem;
    margin-bottom: 1rem;
}
.pre-title {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.1rem;
}
@function calculateRem($size) {
    $remSize: $size / 25px;
    @return #{$remSize}rem;
}
h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    max-width: 60ch;
}
p {
    font-size: 1.05rem;
}
$title-line-height: 4rem;
$title-margin-bottom: 1rem;
$title-font-size: 3.5rem;
h1,
.title {
    margin-bottom: 0;
    font-size: $title-font-size;
    line-height: $title-line-height;
    margin-block-start: 0;
    margin-block-end: 0;
    // @media screen and (max-width: 800px) {
    //     font-size: $title-font-size * 0.9;
    //     line-height: $title-line-height * 0.9;
    // }
    font-family: "Roboto Slab", serif;
    display: inline-block;
    width: 100%;
    // overflow-wrap: break-word;
    // word-wrap: break-word;
    // -webkit-hyphens: auto;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // hyphens: auto;
    font-size: clamp(2.1875rem, 1.6106rem + 2.5641vw, 4.6875rem);
    line-height: 1;
}
.title {
    border-color: $dark;
    // &--lg-border:before {
    //     content: "";
    //     display: block;
    //     z-index: -1;
    //     position: absolute;
    //     height: 85%;
    //     width: 4px;
    //     border-left: 4px solid;
    //     border-color: inherit;
    //     top: 47%;
    //     transform: translateY(-50%);
    //     margin-left: -2rem;
    // }
}
@media only screen and (max-width: 1000px) {
    .title-wrapper--hide-br br {
        display: none;
    }
}
.title-wrapper {
    // @media screen and (min-width: 1001px) {
    //     max-width: 35ch;
    // }
    max-width: 100ch;
    @media screen and (max-width: 1000px) {
        max-width: 100%;
    }
    display: block;
    position: relative;

    &--lg {
        margin-left: 1rem;
        @media screen and (min-width: 1001px) {
            margin: 0 1rem;
        }
        margin-bottom: 2rem;
        position: relative;
    }
    @media screen and (max-width: 1000px) {
        &--lg-on-sm {
            padding-left: 0;
            @media screen and (max-width: 1000px) {
                margin: 0;
            }
            & h1 {
                font-size: #{$title-font-size * 1.5};
                line-height: #{$title-line-height * 1.5};
            }
        }
    }
}
.inherit-all * {
    font-size: inherit !important;
    line-height: inherit !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    // -webkit-hyphens: auto;
    // -ms-hyphens: auto;
    // -moz-hyphens: auto;
    // hyphens: auto;
}
.title_border {
    width: 2.5rem;
    height: 4px;
    background: $dark;
    margin-top: 2rem;
    margin-bottom: 3.8rem;
    &--lg {
        @media screen and (min-width: 1001px) {
            display: none;
        }
    }
}
