.image-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.image {
    max-height: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    &--notsvg {
        object-fit: cover;
    }
    @media screen and (max-width: 800px) {
        max-height: 250px;
    }
    &_credit {
        margin-top: 1rem;
        & p {
            font-size: 0.8rem;
        }
    }
}
.scrollytelling-wrapper {
    width: 100%;
    // overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 15vh 0;
    @media screen and (max-width: 1000px) {
        min-height: 100vh;
    }
}
.scrollytelling-bg-wrapper {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    background-position: center center;
}
.scrollytelling-bg {
    // position: absolute;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    width: 100vw;
    max-width: 100% !important;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    filter: brightness(80%);
    background-attachment: fixed;
}
@supports (-webkit-touch-callout: none) {
    .scrollytelling-bg {
        background-attachment: scroll;
        background-size: auto 100vh;
    }
}
.scrollytelling-content {
    color: white;
    text-align: center;
    font-weight: bold;
    font-family: "Roboto Slab", serif;
    max-width: 1000px;
    margin: auto;
    & * {
        font-size: 1.5rem;
        @media screen and (max-width: 800px) {
            font-size: 1.35rem;
        }
        margin-bottom: 0;
        & + * {
            margin-bottom: 1rem;
        }
    }
}
