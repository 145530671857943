.rich-text {
    & a {
        color: $links;
        font-weight: bold;
        // text-decoration: none;
        // &:hover {
        //     text-decoration: underline;
        // }
    }

    & img {
        object-fit: contain;
    }
    & h1 {
        margin-top: 5rem;
        margin-bottom: 5rem;
        display: inline-block;
    }
    & blockquote {
        font-size: clamp(2.1875rem, 2.0433rem + 0.641vw, 2.8125rem);
        line-height: 1;
        margin-top: 5rem;
        margin-bottom: 4rem;
        display: inline-block;
        font-family: "Roboto Slab", serif;
        &:before {
            content: '"';
        }
    }
    & table {
        display: block;
        border: 0mm;
        overflow-x: auto;
        white-space: nowrap;
        position: relative;
        padding-bottom: 3rem;
        border-spacing: 0;
        border-collapse: separate;
        height: auto !important;
        width: 100% !important;
        margin-bottom: 2rem;
        & p {
            margin-bottom: 0;
        }
        & p + * {
            margin-top: 1rem;
        }
        & td {
            // padding: 1rem;
            padding-top: 0.5rem;
            margin-right: 1rem;
            border-top: 1px solid grey;
            @media screen and (max-width: 1000px) {
                display: block;

                width: 100% !important;
                height: auto !important;
                white-space: normal;
                border: none;
                & * {
                    display: inline;
                    max-width: 100%;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -webkit-hyphens: auto;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    hyphens: auto;
                }
            }
            &:not(:first-of-type) {
                padding-left: 2rem;
            }
        }

        @media screen and (max-width: 1000px) {
            & tbody {
                width: 100% !important;
                height: auto !important;
                display: block;
            }
            & tr {
                width: 100% !important;
                height: auto !important;
                border-top: 1px solid grey !important;
                display: block;
                padding-bottom: 1rem;
            }
        }
        // &:before {
        //     content: "scroll til høyre hvis innholdet ikke passer >";
        //     text-transform: uppercase;
        //     color: grey;
        //     font-weight: bold;
        //     opacity: 0 !important;
        //     position: absolute;
        //     left: 0;
        //     pointer-events: none;
        //     bottom: 1rem;
        //     user-select: none;
        //     font-size: 0.8rem;
        //     @media screen and (max-width: 1000px) {
        //         opacity: 1 !important;
        //     }
        // }
        &::-webkit-scrollbar {
            height: 2px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba($dark, 20%);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
