.featured-articles {
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 100%;
    padding: 3rem;
}
.featured-articles-container {
    padding: 2rem;
}
// .featured-articles__article {
// }
.featured-articles__article__image {
    margin-bottom: 1rem;
    height: 250px;
    object-fit: cover;
    aspect-ratio: 16/9;
}
.featured-articles__article__title {
    color: $green;
    text-decoration: none;
    text-align: left;
    margin-bottom: 1.5rem;
}
// .featured-articles__article__text {
// }
.featured-articles__article__body__title-link {
    text-decoration: none;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 1rem;
    color: $green;
    margin-right: auto;
    &:hover {
        text-decoration: underline;
    }
}
.featured-articles__article__link-wrapper {
    color: $green;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    &:hover {
        text-decoration: underline;
        & .featured-articles__article__image {
            opacity: 0.8;
        }
    }
}
.featured-articles-slider {
    position: relative;
    margin-top: 4rem;
    margin-bottom: 4rem;
    --arrow-size: 1rem;
    width: 100%;
    padding-left: calc(var(--arrow-size) * 6);
    padding-right: calc(var(--arrow-size) * 6);
    @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
    }
    margin: auto;
    --swiper-pagination-color: #007682;
    & .swiper-pagination {
        position: relative;
    }
    & .swiper-navigation {
        @media (max-width: 600px) {
            display: none;
        }
    }
    & .swiper-button-prev,
    .swiper-button-next {
        top: 50%;
        transform: translateY(-50%);
        color: white;
        display: block;
        width: max-content;
        height: max-content;
        background-color: $green;
        border: 0;
        &:after {
            font-size: var(--arrow-size);
            padding: 1rem;
            display: block;
        }
        &:hover {
            filter: brightness(150%);
        }
        &:focus {
            outline: 2px solid $links;
        }
    }
}
.featured-articles-slider .featured-articles__article {
    display: flex;
    align-items: flex-start;
    column-gap: 3rem;
    flex-direction: row-reverse;
    @media (max-width: 1200px) {
        flex-wrap: wrap;
    }
    & .featured-articles__article__title {
        margin-bottom: 1rem;
        text-align: left;
    }
    & .featured-articles__article-body {
    }
    & .featured-articles__article-image {
    }
    & .featured-articles__article__link-wrapper {
        align-items: flex-end;
        @media (max-width: 1200px) {
            align-items: center;
        }
    }
}
