.section {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    position: relative;
    transition: $scrollChangeTransition;
    user-select: none;
    padding: 1rem;
    @media screen and (min-width: 992px) {
        padding: 4rem;
    }
    @media screen and (min-width: 600px) and (max-width: 1000px) {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
    @media screen and (max-width: 1000px) {
        align-items: flex-start;
        padding-top: 100px;
        // min-height: 70vh;
    }
    & .title {
        font-size: clamp(1.5rem, 1.7rem + 1.5vw, 4rem);
    }
    &:last-of-type {
        padding-bottom: 100px;
    }
    & .section-link-wrapper {
        width: 100%;
    }
    & .container {
        padding-bottom: 30px;
        max-width: 1200px;
        width: 100%;
        @media screen and (max-width: 1300px) {
            padding: 0.5rem;
        }
        @media screen and (max-width: 1000px) {
            padding: 0.5rem;
        }
    }
    &_btn-above {
        position: relative;
        z-index: 1;
        font-size: 0.8rem;
        margin-top: 2rem;
    }
    // @media screen and (max-width: 1000px) {
    //     margin-bottom: -1.5rem;
    // }
    &_illustration {
        @media screen and (max-width: 1000px) {
            margin-top: -6rem;
        }
    }
    &_grid {
        display: grid;
        align-items: center;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        & .title-wrapper {
            min-width: 400px;
            @media screen and (max-width: 1000px) and (orientation: landscape) {
                min-width: 350px;
            }
        }
        @media screen and (max-width: 1399px) {
            grid-template-columns: 1fr 0.5fr;
        }
        @media screen and (max-width: 1000px) and (orientation: portrait) {
            grid-template-columns: 1fr;
        }
    }
    &_sneakpeak-arrow {
        position: absolute;
        top: 0;
        left: 10vw;
        transition: $scrollChangeTransition;
        &--hide {
            opacity: 0;
            // transform: translateY(-50px);
        }
        @media screen and (min-width: 1000px) {
            display: none;
        }
    }
}
.section_grid .pre-title {
    @media screen and (max-width: 600px) {
        width: 50ch;
    }
}
