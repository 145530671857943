// body,
// html {
//     max-width: 100vw;
//     overflow-x: hidden;
// }
// html {
//     overflow: overlay;
// }
body {
    transition: $scrollChangeTransition;
}
// #root {
//     width: 100vw;
//     overflow: overlay;
//     position: relative;
// }
// html {
//     overflow: overlay;
//     margin: 0 !important;
//     padding: 0 !important;
//     scrollbar-color: $dark transparent;
//     width: 100%;
//     overflow-x: hidden;
// }
// * {
//     overflow-x: hidden;
//     max-width: 100vw;
// }
body {
    margin: 0 !important;
    padding: 0 !important;
    scrollbar-color: $dark transparent;
    width: 100%;
    overflow-x: hidden;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
