// Colors
$dark: #2e2d2c;
$links: #00b7e7;
$yellow: #ffcc05;
$green: #007682;
$blue: #00b7e7;

// Scroll change color transition
$scrollChangeTransition: all 0.15s linear;
*:focus {
    outline: 2px solid $blue !important;
}
.container {
    margin: 0 auto;
    @media screen and (min-width: 1400px) {
        max-width: 1500px;
        width: 100%;
    }
    @media screen and (max-width: 1399px) {
        width: 100vw;
    }
    &--fullwidth-on-sm {
        @media screen and (max-width: 1000px) {
            max-width: 100vw;
        }
    }
}
.scrollable-sections,
.pg-wrapper {
    overflow-x: hidden;
    width: 100vw;
}
:root {
    --pips-color: #000;
}
.pg-pips {
    & ul li a {
        display: block;
        transition: $scrollChangeTransition;
        background-color: var(--pips-color) !important;
    }
}
.loading-screen,
.error-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow: hidden;
    display: grid;
    place-content: center;
    font-size: 3rem;
}
.no-scroll,
.disable-scroll,
.no-scroll #root,
.disable-scroll #root {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}
// Partials
@import "partials/resets";
@import "partials/tags";
@import "partials/typography";
@import "partials/btn";
@import "partials/mixins";

// Components
@import "components/Nav";
@import "components/Split";
@import "components/Section";
@import "components/ScrollDown";
@import "components/Logo";
@import "components/Article";
@import "components/Video";
@import "components/FAQ";
@import "components/Footer";
@import "components/Illustration";
@import "components/RichText";
@import "components/Image";
@import "components/Header";
@import "components/SocialFeed";
@import "components/Infographic";
@import "components/Scrollable";
@import "components/MultimediaSwiper";
@import "components/FeaturedArticles";
