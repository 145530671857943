.infographic {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    &__item {
        display: grid;
        grid-template-columns: 1fr 4fr;
        align-items: center;
        width: 100%;
        grid-gap: 1rem;
        border-top: #b0b0b0 1px solid;
        &__image {
            width: 100%;
            height: 100%;
        }
        &__content {
            text-align: left;
            display: flex;
            align-items: center;
            grid-gap: 1rem;
        }
    }
}
